<template>
  <b10-base>
    <b10-list
      :title="title"
      :page-store-name="pageStoreName"
      :items="items"
      :filter="filter"
      :search="search"
      :sorter="sorter"
      :sorter-desc="sorterDesc"
      :show-load-more="showLoadMore"
      :scroll-position="scrollPosition"
      empty-message="No se encontraron acciones correctoras"
      wrapper-component="v-list"
      :wrapper-component-props="{ 'two-line': true }"
      :loading="loadingData"
      :showing-filter.sync="showingDialogs.filter"
      :showing-sorter.sync="showingDialogs.sorter"
      :load="loadItems"
      :reload-dirty-items="reloadDirtyItems"
      :page-loaded="pageLoaded"
      pk-name="accion_correctora_ot.idaccion_correctora_ot"
      @click-item="clickListItem"
    >
      <template
        #listItem="slotProps"
      >
        <accion-correctora-list-item
          :item="slotProps.item"
          :index="slotProps.index"
          :readonly="!estadoChecklistPendiente"
          @click-delete="clickDelete"
        />
      </template>
      <volver-parte-checklist-menu-items
        slot="menuItemsLongPress"
        :idparte-trabajo="routeParams.idparte_trabajo"
        :idchecklist-ot="routeParams.idchecklist_ot"
        :idorden-trabajo-matsist="idsParaVolerAtras.idorden_trabajo_matsist"
        :idparte-trabajo-matsist="idsParaVolerAtras.idparte_trabajo_matsist"
        :idgrupo-checklist-ot="idsParaVolerAtras.idgrupo_checklist_ot"
        :idsubsis="idsParaVolerAtras.idsubsis"
      />
    </b10-list>
    <b10-fab-button
      v-if="estadoChecklistPendiente && routeQuery.idgrupo_checklist_ot && routeQuery.idpregunta_checklist_ot && routeQuery.idtanomalia_checklist_ot"
      @click="clickAdd"
    />
  </b10-base>
</template>

<script>
import { listPageMixin } from '@/mixins/listPageMixin'
import Data from './AccionCorrectoraListData'
import AccionCorrectoraListItem from './components/AccionCorrectoraListItem'
import VolverParteChecklistMenuItems from '../components/VolverParteChecklistMenuItems'
import { get } from 'vuex-pathify'
import { CHECKLIST } from '@/utils/consts'

const pageStoreName = 'pagesOfflineAccionCorrectoraList'

export default {
  components: {
    AccionCorrectoraListItem,
    VolverParteChecklistMenuItems,
  },
  mixins: [listPageMixin],
  beforeRouteLeave (to, from, next) {
    return this.beforeRouteLeaveBase(to, from, next)
  },
  data () {
    return {
      pageStoreName,
      checklistOt: {},
      idsParaVolerAtras: {
        idorden_trabajo_matsist: null,
        idparte_trabajo_matsist: null,
        idgrupo_checklist_ot: null,
        idsubsis: null,
      },
    }
  },
  computed: {
    items: get(`${pageStoreName}/items`),
    filter: get(`${pageStoreName}/filter`),
    search: get(`${pageStoreName}/search`),
    sorter: get(`${pageStoreName}/sorter`),
    sorterDesc: get(`${pageStoreName}/sorterDesc`),
    currentPage: get(`${pageStoreName}/currentPage`),
    showLoadMore: get(`${pageStoreName}/showLoadMore`),
    scrollPosition: get(`${pageStoreName}/scrollPosition`),
    estadoChecklistPendiente () {
      return this.checklistOt.checklist_ot && this.checklistOt.checklist_ot.estado === CHECKLIST.estados.pendiente
    },
  },
  async created () {
    // routeQuery.idgrupo_checklist_ot: filtro por grupo de checklist
    // routeQuery.idpregunta_checklist_ot: filtro por pregunta de checklist
    // routeQuery.idtanomalia_checklist_ot: filtro por anomalía
    // routeQuery.idorden_trabajo_matsist: filtro por material afectado
    await this.initStore()
    await this.loadPage()
  },
  methods: {
    async loadPage () {
      this.checklistOt = await this.$offline.checklistOt.row(this.routeParams.idchecklist_ot)
      let materialSistema = null
      if (!!this.routeQuery.idpregunta_checklist_ot){
        const preguntaChecklistOt = await this.$offline.preguntaChecklistOt.row(this.routeQuery.idpregunta_checklist_ot)
        this.title = `Acciones correctoras pregunta ${preguntaChecklistOt.pregunta_checklist_ot.alias}`
        materialSistema = await this.$offline.preguntaChecklistOt.selectMaterialSistemaDePreguntaChecklistOt(
          this.routeQuery.idpregunta_checklist_ot
        )
      } else if (!!this.routeQuery.idorden_trabajo_matsist) {
        materialSistema = await this.$offline.ordenTrabajoMatsist.selectMaterialSistemaDeIdordenTrabajoMatsist(
          this.routeQuery.idorden_trabajo_matsist
        )
        this.title = `Acciones correctoras de ${materialSistema.material_sistema.descripcion}`
      } else {
        this.title = `Acciones correctoras checklist Nº ${this.routeParams.idchecklist_ot}`
      }
      if (!!materialSistema) {
        this.idsParaVolerAtras.idorden_trabajo_matsist = materialSistema.orden_trabajo_matsist.idorden_trabajo_matsist
        this.idsParaVolerAtras.idparte_trabajo_matsist = materialSistema.parte_trabajo_matsist.idparte_trabajo_matsist
        this.idsParaVolerAtras.idsubsis =  materialSistema.material_sistema.idsubsis
      }
      if (!!this.routeQuery.idgrupo_checklist_ot) {
        this.idsParaVolerAtras.idgrupo_checklist_ot = this.routeQuery.idgrupo_checklist_ot
      }
      this.pageLoaded = true
    },
    async loadItems () {
      this.loadingData = true
      try {
        const [dataset, metadata] = await Data.selectAccionCorrectora(
          this,
          this.filter,
          this.search,
          this.sorter,
          this.currentPage,
          this.routeParams.idchecklist_ot,
          this.routeQuery.idgrupo_checklist_ot,
          this.routeQuery.idpregunta_checklist_ot,
          this.routeQuery.idtanomalia_checklist_ot,
          this.routeQuery.idorden_trabajo_matsist
        )
        await this.loadItemsBase(dataset, metadata)
      } finally {
        this.loadingData = false
      }
    },
    async reloadDirtyItems () {
      const dirty = await this.$dirty.popDirty(this.$route.name)
      this.loadingData = true
      try {
        await this.reloadItemsBase(
          await Data.selectAccionCorrectoraRows(this, dirty.modified), dirty, 'accion_correctora_ot.idaccion_correctora_ot'
        )
      } finally {
        this.loadingData = false
      }
    },
    clickListItem (item) {
      this.rememberState = true
      this.rememberScroll = true
      this.$appRouter.push({
        name: 'offline__accion-correctora-view',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
          idgrupo_checklist_ot: item.data.grupo_checklist_ot.idgrupo_checklist_ot,
          idpregunta_checklist_ot: item.data.pregunta_checklist_ot.idpregunta_checklist_ot,
          idtanomalia_checklist_ot: item.data.tanomalia_checklist_ot.idtanomalia_checklist_ot,
          idaccion_correctora_ot: item.data.accion_correctora_ot.idaccion_correctora_ot,
        },
      })
    },
    async clickDelete (data) {
      // TODO: eliminar accion_ejec o artículo si lo metió
      const res = await this.$alert.showConfirm(`¿Deseas eliminar la acción correctora "${data.item.accion_correctora_ot.descripcion}"?`)
      if (res) {
        this.$loading.showManual('Eliminando...')
        try {
          await this.$offline.accionCorrectoraOt.deleteSync(
            data.item.accion_correctora_ot.idaccion_correctora_ot
          )
          await this.dispatchStore('deleteItem', data.index)
        } finally {
          this.$loading.hide()
        }
      }
    },
    async clickAdd () {
      // sin remember porque agrega un registro
      this.$appRouter.push({
        name: 'offline__accion-correctora-add',
        params: {
          idparte_trabajo: this.routeParams.idparte_trabajo,
          idchecklist_ot: this.routeParams.idchecklist_ot,
          idgrupo_checklist_ot: this.routeQuery.idgrupo_checklist_ot,
          idpregunta_checklist_ot: this.routeQuery.idpregunta_checklist_ot,
          idtanomalia_checklist_ot: this.routeQuery.idtanomalia_checklist_ot
        }
      })
    },
  }
}
</script>
