import { likeRegExp } from '@/offline/database'

export default {
  _selectAccionCorrectoraBase (Vue) {
    const tables = Vue.$offline.db.tables
    return Vue.$offline.db
      .select()
      .from(tables.accion_correctora_ot)
      .innerJoin(
        tables.tanomalia_checklist_ot,
        tables.accion_correctora_ot.idtanomalia_checklist_ot.eq(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot)
      )
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.grupo_checklist_ot,
        tables.pregunta_checklist_ot.idgrupo_checklist_ot.eq(tables.grupo_checklist_ot.idgrupo_checklist_ot)
      )
  },
  async selectAccionCorrectora (
    Vue, filter, search, sorter, page, idchecklistOt, idgrupoChecklistOt, idpreguntaChecklistOt, idtanomaliaChecklistOt, idordenTrabajoMatsist
  ) {
    const tables = Vue.$offline.db.tables
    let where = []
    let whereSearch = []
    let whereFilter = []
    if (search) {
      const reQ = likeRegExp(search)
      whereSearch.push(
        Vue.$offline.db.op.or(
          tables.accion_correctora_ot.descripcion.match(reQ),
          tables.accion_correctora_ot.observaciones.match(reQ),
        )
      )
    }
    let whereQueryFilter = []
    if (idgrupoChecklistOt) {
      whereQueryFilter.push(tables.grupo_checklist_ot.idgrupo_checklist_ot.eq(idgrupoChecklistOt))
    }
    if (idpreguntaChecklistOt) {
      whereQueryFilter.push(tables.pregunta_checklist_ot.idpregunta_checklist_ot.eq(idpreguntaChecklistOt))
    }
    if (idtanomaliaChecklistOt) {
      whereQueryFilter.push(tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(idtanomaliaChecklistOt))
    }
    if (idordenTrabajoMatsist) {
      whereQueryFilter.push(tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(idordenTrabajoMatsist))
    }
    where.push(
      Vue.$offline.db.op.and(
        tables.grupo_checklist_ot.idchecklist_ot.eq(idchecklistOt),
        tables.accion_correctora_ot.estado.gt(0),
        ...whereQueryFilter,
        ...whereSearch,
        ...whereFilter
      )
    )
    return [
      await this._selectAccionCorrectoraBase(Vue)
        .where(...where)
        .limit(Vue.$offline.db.ITEMS_PER_PAGE)
        .skip(page * Vue.$offline.db.ITEMS_PER_PAGE)
        .orderBy(tables.accion_correctora_ot.idaccion_correctora_ot)
        .exec(),
      []
    ]
  },
  selectAccionCorrectoraRows (Vue, pks) {
    const tables = Vue.$offline.db.tables
    return this._selectAccionCorrectoraBase(Vue)
      .where(tables.accion_correctora_ot.idaccion_correctora_ot.in(pks))
      .exec()
  },
  async selectMaterialSistemaDeTanomaliaChcklistOT (Vue, idTanomaliaChecklistOt) {
    const tables = Vue.$offline.db.tables
    return (await Vue.$offline.db
      .select()
      .from(tables.tanomalia_checklist_ot)
      .innerJoin(
        tables.pregunta_checklist_ot,
        tables.tanomalia_checklist_ot.idpregunta_checklist_ot.eq(tables.pregunta_checklist_ot.idpregunta_checklist_ot)
      )
      .innerJoin(
        tables.orden_trabajo_matsist,
        tables.pregunta_checklist_ot.idorden_trabajo_matsist.eq(tables.orden_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.parte_trabajo_matsist,
        tables.orden_trabajo_matsist.idorden_trabajo_matsist.eq(tables.parte_trabajo_matsist.idorden_trabajo_matsist)
      )
      .innerJoin(
        tables.material_sistema,
        tables.orden_trabajo_matsist.idmaterial_sistema.eq(tables.material_sistema.idmaterial_sistema)
      )
      .where(
        tables.tanomalia_checklist_ot.idtanomalia_checklist_ot.eq(idTanomaliaChecklistOt)
      ).exec())[0]
  }
}
